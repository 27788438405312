@use "./../Styles/global";

#resources {
  h2 {
    margin-block-start: 1em;
    margin-block-end: 0.5em;
  }

  .resource-groups {
    margin-block-start: var(--size-5);
  }

  .resource-group-wrap {
    display:flex;
    justify-content: center;
  }
  .resource-group {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    max-inline-size: calc(var(--size-content-2) * 4);
    gap: var(--size-5);
    padding-block: var(--size-5);

    a {
      text-decoration: none;
      color: inherit;
    }

    .resource-card {
      border: 1px solid hsl(var(--clr-earth), 0.5);
      box-shadow: var(--shadow-2);
      transition: 0.3s;
      flex-basis: var(--size-content-1);
      display: flex;
      flex-direction: column;
      border-radius: var(--radius-2);
      overflow: hidden;
      max-width:35ch;

      h3 {
        line-height: var(--font-lineheight-2);
      }

      p {
        margin: 0;
      }

      &:hover {
        box-shadow: var(--shadow-4);
      }

      .card-hero {
        width:100%;
        overflow: hidden;
        img {
          width:100%;
        }
      }

      .card-copy {
        padding: var(--size-4);
      }
    }
  }
}
