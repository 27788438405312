@use "./../../Styles/global";

.video-background {
  background: #000;
  position: fixed;
  top: 0;
  // bottom: 0;
  height: 70vh; // taken from .hero
  min-height: 400px; // taken from .hero
  right: 0;
  left: 0;
  z-index: -99;
  // &::after {
  //   display: block;
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   z-index: 1;
  //   background: rgba(0, 0, 0, 0.75);
  // }
}
.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

@media (min-aspect-ratio: 16/9) {
  .video-foreground {
    height: 300%;
    top: -100%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground {
    width: 300%;
    left: -100%;
  }
  .video-foreground,
  .video-background iframe {
    $vid-scale: scale(1.2);
    -ms-transform: $vid-scale;
    -webkit-transform: $vid-scale;
    transform: $vid-scale;
  }
}
