@use "./../Styles/global";

#contact {
    .form-wrap {
        display: flex;
        justify-content: center;

        @media (min-width:1024px) {
            justify-content: flex-start;
        }
    }
}
