@use "./../Styles/global";

#talcott {
  .TalcottLaunchVR {
    margin: 0 auto;
    /*JDS*/
    width: 100%;
    height: 400px;
    background: url("./../Assets/Images/Scenes/PANO_20190811_163508.vr-web.jpg")
      center top repeat-x;
    position: relative;
    z-index: 10;
    overflow: auto;
  }

  @-webkit-keyframes DriftingLaunch {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -2422px 0;
    }
  }

  @-moz-keyframes DriftingLaunch {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -2422px 0;
    }
  }

  @-ms-keyframes DriftingLaunch {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -2422px 0;
    }
  }

  @-o-keyframes DriftingLaunch {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -2422px 0;
    }
  }

  @keyframes DriftingLaunch {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -2422px 0;
    }
  }

  .TalcottLaunchVR {
    -webkit-animation: DriftingLaunch 60s linear infinite;
    -moz-animation: DriftingLaunch 60s linear infinite;
    -ms-animation: DriftingLaunch 60s linear infinite;
    -o-animation: DriftingLaunch 60s linear infinite;
    animation: DriftingLaunch 60s linear infinite;
  }
}
