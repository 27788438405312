@use "./../../Styles/global";

svg.hamburger {
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 2;
  path {
    fill: rgb(247, 242, 238);
    fill-rule: nonzero;
    // stroke: rgb(34, 58, 69);
    // stroke-width: 5.19px;
  }
  &:hover {
    box-shadow: var(--shadow-4);
  }
}
