@use "./../Styles/_global.scss";

#home {
  // $logo-width: 304px;
  $logo-width: 250px;
  .chga-logo {
    width: $logo-width;
  }
  .chga-mission {
    display: flex;
    flex-direction: column;
    gap: 2em;
    align-items: center;

    // @media (min-width: global.$mobile-breakpoint) {
    // }
  }
  // .my-video-player {
  //   width: 100%;
  //   height: 500px;
  // }
  @media (min-width: global.$mobile-breakpoint) {
    .chga-logo {
      min-width: $logo-width;
    }
    .chga-mission {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }
  }
}
