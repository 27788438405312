@use "./../../Styles/global";

.hero {
  height: 70vh;
  min-height: 400px;
  overflow: hidden;
  background-size: cover;
  background-position: center;

  &.hero_chga-4th {
    background-image: url("./../../Assets/Images/New-Heroes/u2-4th-droid-portrait.jpg");

    @media (orientation: landscape) {
      background-image: url("./../../Assets/Images/New-Heroes/u2-4th-droid.jpg");
    }

    @media (min-width: 768px) {
      background-image: url("./../../Assets/Images/New-Heroes/u2-4th-XL.jpg");
    }
  }
  &.hero_heublein-ideal-clouds {
    background-image: url("./../../Assets/Images/New-Heroes/heublein-fantasy-droid-portrait.jpg");

    @media (orientation: landscape) {
      background-image: url("./../../Assets/Images/New-Heroes/heublein-fantasy-droid.jpg");
    }

    @media (min-width: 768px) {
      background-image: url("./../../Assets/Images/New-Heroes/heublein-fantasy-XL.jpg");
    }
  }

  &.hero_falcon-launch {
    background-image: url("./../../Assets/Images/New-Heroes/falcon-launch-droid-portrait.jpg");

    @media (orientation: landscape) {
      background-image: url("./../../Assets/Images/New-Heroes/falcon-launch-droid.jpg");
    }

    @media (min-width: 768px) {
      background-image: url("./../../Assets/Images/New-Heroes/falcon-launch-XL.jpg");
    }
  }

  &.hero_talcott-flyby {
    background-image: url("./../../Assets/Images/New-Heroes/talcott-flyby-droid-portrait.jpg");

    @media (orientation: landscape) {
      background-image: url("./../../Assets/Images/New-Heroes/talcott-flyby-droid.jpg");
    }

    @media (min-width: 768px) {
      background-image: url("./../../Assets/Images/New-Heroes/talcott-flyby-XL.jpg");
    }
  }

  &.hero_tanner-hiller-dragonfly {
    background-image: url("./../../Assets/Images/New-Heroes/tanner-hiller-droid-portrait.jpg");

    @media (orientation: landscape) {
      background-image: url("./../../Assets/Images/New-Heroes/tanner-hiller-droid.jpg");
    }

    @media (min-width: 768px) {
      background-image: url("./../../Assets/Images/New-Heroes/tanner-hiller-XL.jpg");
    }
  }
  &.hero_birds-flock-india {
    background-image: url("./../../Assets/Images/New-Heroes/pexels-kunal-baroth-droid-portrait.jpg");

    @media (orientation: landscape) {
      background-image: url("./../../Assets/Images/New-Heroes/pexels-kunal-baroth-droid.jpg");
    }

    @media (min-width: 768px) {
      background-image: url("./../../Assets/Images/New-Heroes/pexels-kunal-baroth-XL.jpg");
    }
  }

  &.hero_talcott-setup-area {
    background-image: url("./../../Assets/Images/New-Heroes/talcott-setup-droid-portrait.jpg");

    @media (orientation: landscape) {
      background-image: url("./../../Assets/Images/New-Heroes/talcott-setup-droid.jpg");
    }

    @media (min-width: 768px) {
      background-image: url("./../../Assets/Images/New-Heroes/talcott-setup-XL.jpg");
    }
  }

  &.hero_talcott-lz {
    background-image: url("./../../Assets/Images/New-Heroes/talcott-lz-droid-portrait.jpg");

    @media (orientation: landscape) {
      background-image: url("./../../Assets/Images/New-Heroes/talcott-lz-droid.jpg");
    }

    @media (min-width: 768px) {
      background-image: url("./../../Assets/Images/New-Heroes/talcott-lz-XL.jpg");
    }
  }

  &.hero_raptor {
    background-image: url("./../../Assets/Images/New-Heroes/raptor-droid-portrait.jpg");

    @media (orientation: landscape) {
      background-image: url("./../../Assets/Images/New-Heroes/raptor-droid.jpg");
    }

    @media (min-width: 768px) {
      background-image: url("./../../Assets/Images/New-Heroes/raptor-XL.jpg");
    }
  }

  &.hero_dragonfly-glider {
    background-image: url("./../../Assets/Images/New-Heroes/dragonfly-glider-portrait.jpg");

    @media (orientation: landscape) {
      background-image: url("./../../Assets/Images/New-Heroes/dragonfly-glider-droid.jpg");
    }

    @media (min-width: 768px) {
      background-image: url("./../../Assets/Images/New-Heroes/dragonfly-glider-XL.jpg");
    }
  }

  &.hero_talcott-flight-twilight {
    background-image: url("./../../Assets/Images/New-Heroes/talcott-flight-twilight-droid-portrait.jpg");

    @media (orientation: landscape) {
      background-image: url("./../../Assets/Images/New-Heroes/talcott-flight-twilight-droid.jpg");
    }

    @media (min-width: 768px) {
      background-image: url("./../../Assets/Images/New-Heroes/talcott-flight-twilight-XL.jpg");
    }
  }

  &.hero_greylock-flyover {
    background-image: url("./../../Assets/Images/New-Heroes/greylock-flyover-droid-portrait.jpg");

    @media (orientation: landscape) {
      background-image: url("./../../Assets/Images/New-Heroes/greylock-flyover-droid.jpg");
    }

    @media (min-width: 768px) {
      background-image: url("./../../Assets/Images/New-Heroes/greylock-flyover-XL.jpg");
    }
  }

  @keyframes change-bg-home-droid-portrait {
    0%,
    20%,
    100% {
      background-image: url("./../../Assets/Images/New-Heroes/u2-4th-droid-portrait.jpg");
    }
    25%,
    45% {
      background-image: url("./../../Assets/Images/New-Heroes/heublein-fantasy-droid-portrait.jpg");
    }
    50%,
    75% {
      background-image: url("./../../Assets/Images/New-Heroes/falcon-launch-droid-portrait.jpg");
    }
    80%,
    95% {
      background-image: url("./../../Assets/Images/New-Heroes/talcott-flyby-droid-portrait.jpg");
    }
  }

  @keyframes change-bg-home {
    0%,
    20%,
    100% {
      background-image: url("./../../Assets/Images/New-Heroes/u2-4th-XL.jpg");
    }
    25%,
    45% {
      background-image: url("./../../Assets/Images/New-Heroes/heublein-fantasy-XL.jpg");
    }
    50%,
    75% {
      background-image: url("./../../Assets/Images/New-Heroes/falcon-launch-XL.jpg");
    }
    80%,
    95% {
      background-image: url("./../../Assets/Images/New-Heroes/talcott-flyby-XL.jpg");
    }
  }

  &.hero_home_anim {
    animation: change-bg-home-droid-portrait 25s infinite;
    @media (min-width: 768px) {
      animation: change-bg-home 25s infinite;
    }
  }

  .hero-text-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline-start: 0.5em;
    width: 10vw;
    min-width: 100px;
    height: 100%;
  }
  .hero-text {
    opacity: 70%;
    color: hsl(var(--clr-white));
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    text-align: center;
    font-family: var(--ff-headings);
    font-weight: var(--fw-headings-extrabold);
    letter-spacing: var(--font-letterspacing-1);
    font-size: 4rem;
    font-size: clamp(4rem, 3.2rem + 4vw, 8rem);
    &.hero-text_small {
      font-size: 3rem;
      font-size: clamp(3rem, 2.2rem + 4vw, 7rem);
    }
    white-space: nowrap;
    padding-inline: 0.5em;
    margin-block-start: 0.15em;
    border-radius: var(--radius-3);
  }

  .hero-svg-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10vw;
    min-width: 100px;
    height: 100%;
    svg {
      height: 80%;
    }
  }

  // Debugging:
  // @media (min-aspect-ratio: 16/9) {
  //   outline: 3px solid orange;
  // }

  // @media (max-aspect-ratio: 16/9) {
  //   outline: 3px solid limegreen;
  // }
}
