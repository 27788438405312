@use "./../Styles/global";

.site-header {
  display: none;


  .szh-menu-button {
    border: none;
    background-color: transparent;
    color: inherit;
    cursor: pointer;
  }
  .szh-menu {
    background-color: hsla(var(--clr-sky), 70%);
    color: inherit;
    margin-block-start: 6px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    @media (prefers-color-scheme: dark) {
      background-color: hsla(var(--clr-dark-forest), 70%);
    }
  }
  .szh-menu__item:hover {
    background-color: hsla(var(--clr-sky), 80%);
    @media (prefers-color-scheme: dark) {
      background-color: hsla(var(--clr-dark-forest), 70%);
    }
  }
  @media (min-width: global.$mobile-breakpoint) {
    display: flex;
    position: absolute;
    z-index: 1000;
    width: 100%;
    background-color: hsla(var(--clr-sky), 70%);
    color: hsl(var(--clr-dark-forest));

    align-items: center;
    justify-content: center;
    gap: 3vw;

    font-family: var(--ff-headings);
    font-weight: var(--fw-headings-extrabold);
    font-variant: small-caps;
    font-size: var(--fs-500);

    padding-block: 0.25em;

    a {
      text-decoration: none;
      color: inherit;
      &.active-link {
        color: hsl(var(--clr-dark-earth));
        @media (prefers-color-scheme: dark) {
          color: hsl(var(--clr-beach));
        }
      }
    }

      .header-decoration {
        height: 1.5em;
        margin-inline-end: 1.5em;
      }


  

    @media (prefers-color-scheme: dark) {
      background-color: hsla(var(--clr-dark-forest), 70%);
      color: hsl(var(--clr-white));
    }
  }
}

.mobile-site-menu {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1000;
  cursor: pointer;
  border: hidden;
  background-color: transparent;

  img,
  svg {
    width: 50px;
  }

  &.mobile-nav_visible {
    position: fixed;
  }

  @media (min-width: global.$mobile-breakpoint) {
    display: none;
  }
}

.mobile-nav {
  display: none;

  &.mobile-nav_visible {
    display: flex;
    z-index: 999;
    position: fixed;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: hsl(var(--clr-white));
    background-color: hsl(var(--clr-dark-forest));
    min-height: 100vh;
    font-family: var(--ff-headings);
    font-weight: var(--fw-headings-extrabold);
    font-variant: small-caps;
    font-size: var(--fs-600);
    gap: 0.5em;

    .home-icon {
      width: 30vw;
      min-width: 200px;
    }

    a {
      color: inherit;
      text-decoration: none;
    }
    @media (min-width: global.$mobile-breakpoint) {
      display: none;
    }
  }
}
