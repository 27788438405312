@use "./../../Styles/global";

.map_canvas {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  #map {
    .leaflet-container {
      height: 100%;
      min-height: 600px;
      z-index: 1;

      .leaflet-control-zoom-in,
      .leaflet-control-zoom-out {
        padding: 0;
      }
    }
  }
}

.maptiler-logo {
  width: 150px;
}
